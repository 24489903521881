<template>
  <div class="tab-pane fade show active" id="choose-exchange-step" role="tabpanel" aria-labelledby="choose-exchange-step-tab">
    <div>
      <div class="mb-4">
        <div>
          <h5 class="mb-1">{{ $t("t-do-you-have-exchange") }}</h5>
          <!--                        <p class="text-muted">Fill all Information as below</p>-->
        </div>
      </div>
      <div class="row">

        <div class="col-12">
          <span class="border border-2 me-4 p-2">
            <img src="@/assets/images/integrations/bitget.png" height="30">
          </span>
          <span class="border border-2 me-4 p-2">
            <img src="@/assets/images/integrations/binance.png" height="30">
          </span>
          <span class="border border-2 me-4 p-2">
            <img src="@/assets/images/integrations/bybit.png" height="30">
          </span>
        </div>
        <div class="col-12 text-center mb-3 mt-4">
          <!-- Radio Buttons -->
          <b-button-group role="group" aria-label="Do you have exchange account?">
            <input type="radio" class="btn-check" name="have_exchange" id="have_exchange_yes" value="yes" autocomplete="off" >
            <label class="btn btn-outline-secondary" for="have_exchange_yes">{{ $t("t-yes") }}</label>

            <input type="radio" class="btn-check" name="have_exchange" id="have_exchange_no" value="no" autocomplete="off" checked="">
            <label class="btn btn-outline-secondary" for="have_exchange_no">{{ $t("t-no") }}</label>
          </b-button-group>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="register-exchange-step-tab" id="step1">
        <div class="btn-content">
          <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          {{ $t("t-continue") }}
        </div>
      </button>
    </div>
  </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  }
};
</script>